import { enableFormLoading } from "../../redux/actions";
import { APICore } from "./apiCore";
import { clearLocalStorage, preLoginCall } from "./utils";
import { useEffect } from "react";
import { notification } from "antd";

const api = new APICore();

const preBaseURL = `${localStorage.getItem("reqUrl")}/api/`;
if (!localStorage.getItem("reqUrl")) {
  window.location.href = "/";
}

// account
function login(params: { str_username: string; str_password: string }) {
  const baseUrl = `${preBaseURL}login`;

  return api.create(`${baseUrl}`, params);
}
function getUserDetails() {
  const apiUrl = `${preBaseURL}user/me`;
  return api.getUserDetails(apiUrl);
}
function logout() {
  return api.create(`${preBaseURL}logout`, {});
}
function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}
function forgotPassword(params: { email: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

async function requestTicketInputShareWithListing(payload: any) {
  const apiUrl = `${preBaseURL}search/user/by-id/${payload}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}
async function requestHelpDeskDropdown(payload: string) {
  const apiUrl = payload
    ? `${preBaseURL}helpdesk/?object_type=${payload}`
    : `${preBaseURL}helpdesk/`;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status === 401) {
      clearLocalStorage();
      window.location.href = "/";
    }
    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestRelatedTicket(payload: any) {
  // const apiUrl = `${preBaseURL}search/content/title-by-id/2021527`;
  const apiUrl = `${preBaseURL}search/ticket/subject-by-id/${payload}`;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestRelatedContent(payload: any) {
  const apiUrl = `${preBaseURL}search/content/title-by-id/${payload}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestSearchContentByTitle(payload: any) {
  const apiUrl = `${preBaseURL}search/content/by-title?${payload}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestSearchTicketBySubject(payload: any) {
  const apiUrl = `${preBaseURL}search/ticket/by-subject?${payload}`;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestClassificationNode(payload: any) {
  const apiUrl = payload
    ? `${preBaseURL}classification/sub-nodes/${payload}`
    : `${preBaseURL}classification/`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestSearchTaxoByName(payload: any) {
  const apiUrl = `${preBaseURL}classification/by-name/${payload}`;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestFavoriteTaxo(payload: any) {
  const apiUrl = `${preBaseURL}classification/favorite`;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestAddFavoriteTaxo(payload: any) {
  const apiUrl = `${preBaseURL}classification/favorite`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestRemoveFavoriteTaxo(payload: any) {
  const apiUrl = `${preBaseURL}classification/favorite/${payload}`;
  try {
    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestMyTicketListing(payload: any) {
  const apiUrl = `${preBaseURL}search/ticket/my`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestMyContentListing(payload: any) {
  const apiUrl = `${preBaseURL}search/content/my`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestHDTicketListing(payload: any) {
  const apiUrl = `${preBaseURL}search/ticket/hd`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestHDContentListing(payload: any) {
  const apiUrl = `${preBaseURL}search/content/hd`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

// async function helperRequestSaveTicketInput(payload: any) {
//   const apiUrl = `${preBaseURL}ticket/`;
//   try {
//     const response = await fetch(apiUrl, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(payload),
//     });

//     const jsonData = await response.json();
//     return jsonData;
//   } catch (error) {
//     console.error("Fetch error:", error);
//   }
// }

async function helperRequestSaveTicketInput(payload: any) {
  const apiUrl = `${preBaseURL}ticket/`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return { statusCode: response.status, data: jsonData };
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the ticket input.",
    });
    throw error;
  }
}

async function helperRequestSaveContentInput(payload: any) {
  const apiUrl = `${preBaseURL}content/`;
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while saving the content input.",
    });
  }
}

async function helperRequestStatusFilter(payload: any) {
  const apiUrl = `${preBaseURL}status/?status_for=${payload}`;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while filtering the status.",
    });
  }
}

async function helperRequestContentTypeFilter() {
  const apiUrl = `${preBaseURL}content-type/`;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while fetching the content types.",
    });
  }
}

async function helperRequestPriorityDropdownList() {
  const apiUrl = `${preBaseURL}priority/`;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage();
    } else {
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description:
        "An error occurred while fetching the priority dropdown list.",
    });
  }
}

async function helperRequestCompanyDropdownList() {
  const apiUrl = `${preBaseURL}company/`;
  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const jsonData = await response.json();
      return jsonData;
    } else if (response.status === 401) {
      notification.error({
        message: "Unauthorized",
        description: "Your session has expired. Please log in again.",
      });
      preLoginCall();
      clearLocalStorage(); // Handle session expiration
    } else {
      // Handle other status codes
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  } catch (error) {
    console.error("Fetch error:", error);
    notification.error({
      message: "Network Error",
      description: "An error occurred while fetching data.",
    });
  }
}

async function APIRequest(url: string, method: string, body?: any) {
  const apiUrl = `${preBaseURL}${url}`;
  if (localStorage.getItem("reqUrl")) {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    const requestOptions: RequestInit = {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (response.status === 401) {
        clearLocalStorage();
        window.location.href = "/";
      } else if (response.status === 200) {
        const jsonData = await response.json();
        return jsonData;
      } else {
        // Show Ant Design notification for other status codes
        notification.error({
          message: "Request Error",
          description: "Something went wrong. Please try again.",
        });
      }
    } catch (error) {
      console.error("Fetch error:", error);
      notification.error({
        message: "Request Error",
        description: "Something went wrong. Please try again.",
      });
    }
  }
}

export {
  APIRequest,
  helperRequestCompanyDropdownList,
  helperRequestPriorityDropdownList,
  helperRequestContentTypeFilter,
  helperRequestStatusFilter,
  login,
  logout,
  signup,
  forgotPassword,
  getUserDetails,
  requestTicketInputShareWithListing,
  requestHelpDeskDropdown,
  helperRequestRelatedTicket,
  helperRequestRelatedContent,
  helperRequestSearchContentByTitle,
  helperRequestSearchTicketBySubject,
  helperRequestClassificationNode,
  helperRequestSearchTaxoByName,
  helperRequestFavoriteTaxo,
  helperRequestAddFavoriteTaxo,
  helperRequestRemoveFavoriteTaxo,
  helperRequestMyTicketListing,
  helperRequestMyContentListing,
  helperRequestSaveTicketInput,
  helperRequestSaveContentInput,
  helperRequestHDTicketListing,
  helperRequestHDContentListing,
};
