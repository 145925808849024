export enum AuthActionTypes {
  API_RESPONSE_SUCCESS = "@@auth/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@auth/API_RESPONSE_ERROR",

  LOGIN_USER = "@@auth/LOGIN_USER",
  LOGOUT_USER = "@@auth/LOGOUT_USER",
  SIGNUP_USER = "@@auth/SIGNUP_USER",
  FORGOT_PASSWORD = "@@auth/FORGOT_PASSWORD",
  FORGOT_PASSWORD_CHANGE = "@@auth/FORGOT_PASSWORD_CHANGE",

  RESET = "@@auth/RESET",

  REQUEST_SHARE_WITH_LISTING = "@@auth/REQUEST_SHARE_WITH_LISTING",
  RECEIVE_SHARE_WITH_LISTING = "@@auth/RECEIVE_SHARE_WITH_LISTING",

  REQUEST_HELP_DESK_DROPDOWN = "@@auth/REQUEST_HELP_DESK_DROPDOWN",
  RECEIVE_HELP_DESK_DROPDOWN = "@@auth/RECEIVE_HELP_DESK_DROPDOWN",

  REQUEST_RELATED_TICKET = "@@auth/REQUEST_RELATED_TICKET",
  RECEIVE_RELATED_TICKET = "@@auth/RECEIVE_RELATED_TICKET",

  REQUEST_RELATED_CONTENT = "@@auth/REQUEST_RELATED_CONTENT",
  RECEIVE_RELATED_CONTENT = "@@auth/RECEIVE_RELATED_CONTENT",

  REQUEST_SEARCH_CONTENT_BY_TITLE = "@@auth/REQUEST_SEARCH_CONTENT_BY_TITLE",
  RECEIVE_SEARCH_CONTENT_BY_TITLE = "@@auth/RECEIVE_SEARCH_CONTENT_BY_TITLE",

  REQUEST_SEARCH_TICKET_BY_SUBJECT = "@@auth/REQUEST_SEARCH_TICKET_BY_SUBJECT",
  RECEIVE_SEARCH_TICKET_BY_SUBJECT = "@@auth/RECEIVE_SEARCH_TICKET_BY_SUBJECT",

  ENABLE_SEARCH_LOADER = "ENABLE_SEARCH_LOADER",

  REQUEST_CLASSIFICATION_NODES = "@@auth/REQUEST_CLASSIFICATION_NODES",
  RECEIVE_CLASSIFICATION_NODES = "@@auth/RECEIVE_CLASSIFICATION_NODES",

  REQUEST_CLASSIFICATION_NODES_CHILD = "@@auth/REQUEST_CLASSIFICATION_NODES_CHILD",
  RECEIVE_CLASSIFICATION_NODES_CHILD = "@@auth/RECEIVE_CLASSIFICATION_NODES_CHILD",

  REQUEST_SEARCH_TAXO_BY_NAME = "@@auth/REQUEST_SEARCH_TAXO_BY_NAME",
  RECEIVE_SEARCH_TAXO_BY_NAME = "@@auth/RECEIVE_SEARCH_TAXO_BY_NAME",

  REQUEST_FAVORITE_TAXO = "@@auth/REQUEST_FAVORITE_TAXO",
  RECEIVE_FAVORITE_TAXO = "@@auth/RECEIVE_FAVORITE_TAXO",

  REQUEST_ADD_FAVORITE_TAXO = "@@auth/REQUEST_ADD_FAVORITE_TAXO",
  RECEIVE_ADD_FAVORITE_TAXO = "@@auth/RECEIVE_ADD_FAVORITE_TAXO",

  REQUEST_REMOVE_FAVORITE_TAXO = "@@auth/REQUEST_REMOVE_FAVORITE_TAXO",
  RECEIVE_REMOVE_FAVORITE_TAXO = "@@auth/RECEIVE_REMOVE_FAVORITE_TAXO",

  REQUEST_MY_TICKET_LISTING = "@@auth/REQUEST_MY_TICKET_LISTING",
  RECEIVE_MY_TICKET_LISTING = "@@auth/RECEIVE_MY_TICKET_LISTING",

  REQUEST_MY_CONTENT_LISTING = "@@auth/REQUEST_MY_CONTENT_LISTING",
  RECEIVE_MY_CONTENT_LISTING = "@@auth/RECEIVE_MY_CONTENT_LISTING",

  REQUEST_HD_CONTENT_LISTING = "@@auth/REQUEST_HD_CONTENT_LISTING",
  RECEIVE_HD_CONTENT_LISTING = "@@auth/RECEIVE_HD_CONTENT_LISTING",

  REQUEST_HD_TICKET_LISTING = "@@auth/REQUEST_HD_TICKET_LISTING",
  RECEIVE_HD_TICKET_LISTING = "@@auth/RECEIVE_HD_TICKET_LISTING",

  REQUEST_SAVE_TICKET_INPUT = "@@auth/REQUEST_SAVE_TICKET_INPUT",
  RECEIVE_SAVE_TICKET_INPUT = "@@auth/RECEIVE_SAVE_TICKET_INPUT",

  REQUEST_SAVE_CONTENT_INPUT = "@@auth/REQUEST_SAVE_CONTENT_INPUT",
  RECEIVE_SAVE_CONTENT_INPUT = "@@auth/RECEIVE_SAVE_CONTENT_INPUT",

  RECEIVE_RESPONSE_ADD_FAV_TAXO = "@@auth/RECEIVE_RESPONSE_ADD_FAV_TAXO",

  REQUEST_STATUS_FILTER = "@@auth/REQUEST_STATUS_FILTER",
  RECEIVE_STATUS_FILTER = "@@auth/RECEIVE_STATUS_FILTER",

  REQUEST_CONTENT_TYPE_FILTER = "@@auth/REQUEST_CONTENT_TYPE_FILTER",
  RECEIVE_CONTENT_TYPE_FILTER = "@@auth/RECEIVE_CONTENT_TYPE_FILTER",

  REQUEST_PRIORITY_DROPDOWN = "@@auth/REQUEST_PRIORITY_DROPDOWN",
  RECEIVE_PRIORITY_DROPDOWN = "@@auth/RECEIVE_PRIORITY_DROPDOWN",

  REQUEST_COMPANY_DROPDOWN = "@@auth/REQUEST_COMPANY_DROPDOWN",
  RECEIVE_COMPANY_DROPDOWN = "@@auth/RECEIVE_COMPANY_DROPDOWN",

  REQUEST_DETAILS = "@@auth/REQUEST_DETAILS",
  RECEIVE_DETAILS = "@@auth/RECEIVE_DETAILS",

  ENABLE_FORM_LOADING = "@@auth/ENABLE_FORM_LOADING",
  DISABLE_FORM_LOADING = "@@auth/DISABLE_FORM_LOADING",

  REQUEST_EDIT_TICKET = "@@auth/REQUEST_EDIT_TICKET",
  RECEIVE_EDIT_TICKET = "@@auth/RECEIVE_EDIT_TICKET",

  REQUEST_EDIT_SHARED_WITH = "@@auth/REQUEST_EDIT_SHARED_WITH",
  RECEIVE_EDIT_SHARED_WITH = "@@auth/RECEIVE_EDIT_SHARED_WITH",

  REQUEST_EDIT_TAXO_TICKET = "@@auth/REQUEST_EDIT_TAXO_TICKET",
  RECEIVE_EDIT_TAXO_TICKET = "@@auth/RECEIVE_EDIT_TAXO_TICKET",

  REQUEST_DELETE_RECORD = "@@auth/REQUEST_DELETE_RECORD",
  RECEIVE_DELETE_RECORD = "@@auth/RECEIVE_DELETE_RECORD",

  REQUEST_COMMUNICATION_FROM = "@@auth/REQUEST_COMMUNICATION_FROM",
  RECEIVE_COMMUNICATION_FROM = "@@auth/RECEIVE_COMMUNICATION_FROM",

  REQUEST_COMMUNICATION_ACTIONS = "@@auth/REQUEST_COMMUNICATION_ACTIONS",
  RECEIVE_COMMUNICATION_ACTIONS = "@@auth/RECEIVE_COMMUNICATION_ACTIONS",

  REQUEST_CLASSIFICATION_FROM_CLASS = "@@auth/REQUEST_CLASSIFICATION_FROM_CLASS",
  RECEIVE_CLASSIFICATION_FROM_CLASS = "@@auth/RECEIVE_CLASSIFICATION_FROM_CLASS",

  REQUEST_MOVE_TO_ANOTHER_HD = "@@auth/REQUEST_MOVE_TO_ANOTHER_HD",
  RECEIVE_MOVE_TO_ANOTHER_HD = "@@auth/RECEIVE_MOVE_TO_ANOTHER_HD",

  REQUEST_DUPLICATE = "@@auth/REQUEST_DUPLICATE",
  RECEIVE_DUPLICATE = "@@auth/RECEIVE_DUPLICATE",

  REQUEST_FILE_ATTACHMENT = "@@auth/REQUEST_FILE_ATTACHMENT",
  RECEIVE_FILE_ATTACHMENT = "@@auth/RECEIVE_FILE_ATTACHMENT",

  REQUEST_DELETE_TICKET = "@@auth/REQUEST_DELETE_TICKET",
  RECEIVE_DELETE_TICKET = "@@auth/RECEIVE_DELETE_TICKET",

  REQUEST_COMMUNICATION_THREADS = "@@auth/REQUEST_COMMUNICATION_THREADS",
  RECEIVE_COMMUNICATION_THREADS = "@@auth/RECEIVE_COMMUNICATION_THREADS",

  REQUEST_RELATED_DATA = "@@auth/REQUEST_RELATED_DATA",
  RECEIVE_RELATED_DATA = "@@auth/RECEIVE_RELATED_DATA",

  REQUEST_UPLOAD_DATA = "@@auth/REQUEST_UPLOAD_DATA",
  RECEIVE_UPLOAD_DATA = "@@auth/RECEIVE_UPLOAD_DATA",

  REQUEST_UPLOAD_DATA_DELETE = "@@auth/REQUEST_UPLOAD_DATA_DELETE",
  RECEIVE_UPLOAD_DATA_DELETE = "@@auth/RECEIVE_UPLOAD_DATA_DELETE",

  REQUEST_ADD_RELATED_DATA = "@@auth/REQUEST_ADD_RELATED_DATA",
  RECEIVE_ADD_RELATED_DATA = "@@auth/RECEIVE_ADD_RELATED_DATA",

  REQUEST_ASSIGN_TO = "@@auth/REQUEST_ASSIGN_TO",
  RECEIVE_ASSIGN_TO = "@@auth/RECEIVE_ASSIGN_TO",

  REQUEST_WORK_LOG_THREADS = "@@auth/REQUEST_WORK_LOG_THREADS",
  RECEIVE_WORK_LOG_THREADS = "@@auth/RECEIVE_WORK_LOG_THREADS",

  REQUEST_COMMUNICATION_LOG_THREADS = "@@auth/REQUEST_COMMUNICATION_LOG_THREADS",
  RECEIVE_COMMUNICATION_LOG_THREADS = "@@auth/RECEIVE_COMMUNICATION_LOG_THREADS",

  REQUEST_INSERT_COMMUNICATION = "@@auth/REQUEST_INSERT_COMMUNICATION",
  RECEIVE_INSERT_COMMUNICATION = "@@auth/RECEIVE_INSERT_COMMUNICATION",

  REQUEST_INFORMATION_UPDATE = "@@auth/REQUEST_INFORMATION_UPDATE",
  RECEIVE_INFORMATION_UPDATE = "@@auth/RECEIVE_INFORMATION_UPDATE",

  REQUEST_NOTIFICATION_UPDATE = "@@auth/REQUEST_NOTIFICATION_UPDATE",
  RECEIVE_NOTIFICATION_UPDATE = "@@auth/RECEIVE_NOTIFICATION_UPDATE",

  REQUEST_SEARCHABLE_UPDATE = "@@auth/REQUEST_SEARCHABLE_UPDATE",
  RECEIVE_SEARCHABLE_UPDATE = "@@auth/RECEIVE_SEARCHABLE_UPDATE",

  REQUEST_SEARCH_QUERY = "@@auth/REQUEST_SEARCH_QUERY",
  RECEIVE_SEARCH_QUERY = "@@auth/RECEIVE_SEARCH_QUERY",

  ADD_NAV_QUERY = "@@auth/ADD_NAV_QUERY",
  ADD_NAV_QUERY_TYPE = "@@auth/ADD_NAV_QUERY_TYPE",
}
